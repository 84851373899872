import Product from "./components/ProductCard.vue";
import Api from "@/api/api";
import API from "@/api/config";
import Observer from "../Observer";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  data() {
    return {
      fsRatioOptions: {
        width: "120px",
        min: 0,
        max: 100,
        interval: 25,
        tooltip: "always",
        clickable: false
      },
      designMarketVip: false,
      activeBtnProductType: "A",
      initPage: true,
      requestParams: {},
      newFilter: false,
      warningTitle: this.$t("Design_Message_21"),
      warningMessage: this.$t("Design_Message_20"),
      firstLoading: true,
      filterSpdChips: [],
      activeBtn: "btn5",
      stone: 0,
      enamel: 0,
      resinMinMax: [],
      hasFilter: false,
      hasEnamel: false,
      hasStones: false,
      fsRatioOld: [0, 100],
      fsRatio: [0, 100],
      totalProduct: 0,

      searchValue: null,
      searchValueChip: null,
      searchRequestActive: false,
      isSearchValueActive: false,

      sortOption: [
        {
          id: "-publish_date",
          label: this.$t("Newest_Products")
        },
        {
          id: "title",
          label: this.$t("Sort_By_Name")
        },
        {
          id: "resin_gr",
          label: this.$t("Light_Products_First")
        },
        {
          id: "-resin_gr",
          label: this.$t("Heavy_Products_First")
        }
      ],
      sortValue: null,

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.1
      },

      //Filters

      //GoldGr
      goldWeight: "14K",
      goldWeightRange: [1, 100],
      resingFilterActivate: false,
      filterGoldWeightChips: [],

      //Stone Count
      stoneCountRange: [1, 100],
      stoneCount: 0,
      stoneCountFilterActivate: false,

      // Decoration
      decorations: [],
      selectedDecoration: [],

      // Shape Form
      shapeForms: [],
      selectedShapeForm: [],

      // Attribute
      shapePatternDecoration: [],
      selectedShapePatternDecoration: [],

      //Styles
      selectedStyle: [],
      styleOptions: [],
      styles: [],
      filterStyleChips: [],

      // Collections
      selectedCollection: [],
      CollectionOptions: [],

      // Designers
      designers: [],
      selectedDesigner: [],

      // Categorys
      categories: [],
      selectedCategory: null,
      CategorynOptions: [],
      filterCategoryChips: [],

      //Pagination
      nextPage: "first",

      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,

      // Models
      models: []
    };
  },
  components: {
    productCard: Product,
    Observer,
    VuePerfectScrollbar,
    vSelect,
    VueSlider
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth();
    },
    hasStones() {
      this.activeBtn = "btn5";
    }
  },
  methods: {
    scrollHandle(event) {
      if (event.cancelable) {
        event.preventDefault();
      }
    },
    removeSearchChip() {
      this.isSearchValueActive = false;
      this.searchValue = null;
      this.searchValueChip = null;
      this.getProductWithFilter();
    },
    removeCategoryChips(item) {
      this.filterCategoryChips.splice(
        this.filterCategoryChips.indexOf(item),
        1
      );
      this.selectedCategory = null;
      this.getProductWithFilter();
    },
    removeGoldWeightChips(item) {
      this.filterGoldWeightChips.splice(
        this.filterGoldWeightChips.indexOf(item),
        1
      );

      this.updateGoldWeight(this.goldWeight);

      this.resingFilterActivate = false;

      this.getProductWithFilter();
    },
    removeStyleChips(item) {
      this.filterStyleChips.splice(this.filterStyleChips.indexOf(item), 1);

      var i;
      for (i = 0; i < this.styleOptions.length; i++) {
        if (this.styleOptions[i].label == item) {
          this.selectedStyle.splice(
            this.selectedStyle.indexOf(this.styleOptions[i].id),
            1
          );
          break;
        }
      }

      this.getProductWithFilter();
    },
    removeSpdChips(item) {
      this.filterSpdChips.splice(this.filterSpdChips.indexOf(item), 1);

      var i;
      for (i = 0; i < this.shapePatternDecoration.length; i++) {
        if (this.shapePatternDecoration[i].title == item) {
          this.selectedShapePatternDecoration.splice(
            this.selectedShapePatternDecoration.indexOf(
              this.shapePatternDecoration[i].id
            ),
            1
          );
          break;
        }
      }

      this.getProductWithFilter();
    },

    setGoldWeightRange(weightRange) {
      this.goldWeightRange[0] = weightRange[0];
      this.goldWeightRange[1] = weightRange[1];
    },
    sendStoneCount(rate) {
      this.stoneCountFilterActivate = true;
      this.stoneCountRange[0] = rate[0];
      this.stoneCountRange[1] = rate[1];
      this.getProductWithFilter();
    },
    changedFSRatio() {
      if (
        this.fsRatioOld[0] != this.fsRatio[0] ||
        this.fsRatioOld[1] != this.fsRatio[1]
      ) {
        this.fsRatioOld[0] = this.fsRatio[0];
        this.fsRatioOld[1] = this.fsRatio[1];
        this.getProductWithFilter();
      }
    },

    clearFilters() {
      this.selectedStyle = [];
      this.fsRatio = [0, 100];
      this.hasEnamel = false;
      this.hasStones = false;
      this.hasFilter = false;
      this.sortValue = null;
      this.searchValue = null;
      this.searchValueChip = null;
      this.isSearchValueActive = false;
      this.selectedCategory = null;
      this.selectedShapePatternDecoration = [];
      this.selectedDesigner = [];
      this.selectedCollection = [];
      this.goldWeightRange = [1, 100];
      this.stoneCountRange = [0, 1000];
      this.resingFilterActivate = false;
      this.activeBtnProductType = "A";

      this.getProductWithFilter();
      this.filterGoldWeightChips = [];
      this.warningTitle = this.$t("Design_Message_19");
      this.warningMessage = this.$t("Design_Message_20");
      this.firstLoading = true;
    },
    getProductWithFilter() {
      var params = {};
      if (this.searchRequestActive) {
        return;
      }

      if (this.activeBtnProductType != "A") {
        params.type = this.activeBtnProductType;
      }

      if (this.searchValue != null && this.searchValue != "") {
        this.searchRequestActive = true;
        this.searchValueChip = this.searchValue;
        this.isSearchValueActive = true;
        params.search = this.searchValue;
        this.newFilter = true;
      } else {
        this.isSearchValueActive = false;
      }

      this.filterCategoryChips = [];
      if (this.selectedCategory != null) {
        params.category = String(this.selectedCategory.id);
        this.filterCategoryChips.push(this.selectedCategory.label);
      }
      if (this.resingFilterActivate) {
        this.filterGoldWeightChips = [];

        this.filterGoldWeightChips = [];
        if (this.goldWeightRange[0] < 0) {
          this.goldWeightRange[0] = 0;
        } else if (this.goldWeightRange[1] > 500) {
          this.goldWeightRange[1] = 500;
        }

        if (this.goldWeight == "14K") {
          params.weight_min = (
            this.goldWeightRange[0] / this.goldWeightMultipier.gold14K
          ).toFixed(5);
          params.weight_max = (
            this.goldWeightRange[1] / this.goldWeightMultipier.gold14K
          ).toFixed(5);
          this.filterGoldWeightChips.push(
            this.goldWeightRange[0] + " - " + this.goldWeightRange[1]
          );
          params.gold_type = "14K";
        } else if (this.goldWeight == "18K") {
          params.weight_min = (
            this.goldWeightRange[0] / this.goldWeightMultipier.gold18K
          ).toFixed(5);
          params.weight_max = (
            this.goldWeightRange[1] / this.goldWeightMultipier.gold18K
          ).toFixed(5);
          this.filterGoldWeightChips.push(
            this.goldWeightRange[0] + " - " + this.goldWeightRange[1]
          );
          params.gold_type = "18K";
        } else {
          params.weight_min = (
            this.goldWeightRange[0] / this.goldWeightMultipier.gold21K
          ).toFixed(5);
          params.weight_max = (
            this.goldWeightRange[1] / this.goldWeightMultipier.gold21K
          ).toFixed(5);
          this.filterGoldWeightChips.push(
            this.goldWeightRange[0] + " - " + this.goldWeightRange[1]
          );
          params.gold_type = "21K";
        }
      }

      if (this.fsRatio[0] != 0 || this.fsRatio[1] != 100) {
        if (this.fsRatio[0] == 0 && this.fsRatio[1] == 0) {
          params.fsr_min = 90;
          params.fsr_max = 100;
        } else if (this.fsRatio[0] == 100 && this.fsRatio[1] == 100) {
          params.fsr_min = 0;
          params.fsr_max = 10;
        } else if (Math.abs(100 - this.fsRatio[0]) < 100 - this.fsRatio[1]) {
          params.fsr_min = Math.abs(100 - this.fsRatio[0]);
          params.fsr_max = 100 - this.fsRatio[1];
        } else {
          params.fsr_max = Math.abs(100 - this.fsRatio[0]);
          params.fsr_min = 100 - this.fsRatio[1];
        }
      }

      if (this.stoneCountFilterActivate && this.hasStones) {
        if (this.activeBtn != "btn5") {
          params.stcount_min = this.stoneCountRange[0];
          params.stcount_max = this.stoneCountRange[1];
        }
      }

      this.filterStyleChips = [];
      this.selectedStyle.forEach(element => {
        var i;
        for (i = 0; i < this.styleOptions.length; i++) {
          if (this.styleOptions[i].id == element) {
            this.filterStyleChips.push(this.styleOptions[i].label);
            break;
          }
        }
        if (params.style) {
          params.style += "," + String(element);
        } else {
          params.style = String(element);
        }
      });

      this.filterSpdChips = [];
      this.selectedShapePatternDecoration.forEach(element => {
        var i;
        for (i = 0; i < this.shapePatternDecoration.length; i++) {
          if (this.shapePatternDecoration[i].id == element) {
            this.filterSpdChips.push(this.shapePatternDecoration[i].title);
          }
        }

        if (params.spd) {
          params.spd += "," + String(element);
        } else {
          params.spd = String(element);
        }
      });

      //  this.selectedCollection.forEach(element => {
      //   if (params.collections) {
      //     params.collections += "," + String(element);
      //   } else {
      //     params.collections = String(element);
      //   }
      // });

      if (this.sortValue != null) {
        params.ordering = this.sortValue.id;
      }

      if (this.hasEnamel) {
        params.enamel = this.hasEnamel;
      }
      if (this.hasStones) {
        params.stone = this.hasStones;
      }

      if (this.initPage) {
        if (Object.keys(this.$route.query).length != 0) {
          params = JSON.parse(JSON.stringify(this.$route.query));
        }
      }

      this.$router.push({ query: params }).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });

      this.getFilterValues(params);
      this.newFilter = true;
    },
    updateGoldWeight(gold) {
      this.goldWeight = gold;
      if (gold == "14K") {
        this.goldWeightRange[0] = (
          this.resinMinMax[0] * this.goldWeightMultipier.gold14K
        ).toFixed(1);
        this.goldWeightRange[1] = (
          this.resinMinMax[1] * this.goldWeightMultipier.gold14K
        ).toFixed(1);
      } else if (gold == "18K") {
        this.goldWeightRange[0] = (
          this.resinMinMax[0] * this.goldWeightMultipier.gold18K
        ).toFixed(1);
        this.goldWeightRange[1] = (
          this.resinMinMax[1] * this.goldWeightMultipier.gold18K
        ).toFixed(1);
      } else if (gold == "21K") {
        this.goldWeightRange[0] = (
          this.resinMinMax[0] * this.goldWeightMultipier.gold21K
        ).toFixed(1);
        this.goldWeightRange[1] = (
          this.resinMinMax[1] * this.goldWeightMultipier.gold21K
        ).toFixed(1);
      }
    },
    async intersected() {
      // PAGINATION

      if (this.nextPage == null) {
        return;
      }

      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1
      });

      var thisHere = this;

      setTimeout(function() {
        thisHere.getProducts();
      }, 100);
    },

    handleGetFilterValuesRequest(status, data) {
      if (status == 200) {
        this.searchRequestActive = false;
        this.stone = data.stone;
        this.enamel = data.enamel;

        this.categories = data.category;
        this.designers = data.designers;

        this.attributes = data.attribute;
        this.shapeForms = data.shape_form;
        this.decorations = data.decoration;

        if (this.firstLoading) {
          this.resinMinMax = [data.resin.min, data.resin.max];
          this.updateGoldWeight(this.goldWeight);
        }

        this.shapePatternDecoration = data.shape_pattern_decoration;
        this.shapePatternDecoration.forEach(element => {
          if (this.$i18n.locale == "tr" && element.title_tr != "") {
            element.title = element.title_tr;
          }
        });

        this.styles = data.style;
        this.styleOptions = [];
        this.styles.forEach((element, index) => {
          this.styleOptions[index] = {
            id: element.style,
            label: this.$t(element.title),
            total: element.total
          };
        });

        this.CategorynOptions = [];
        this.categories.forEach((element, index) => {
          var categoryLabel;
          if (this.$i18n.locale == "tr" && element.title_tr != "") {
            categoryLabel = element.title_tr;
          } else {
            categoryLabel = element.title;
          }
          this.CategorynOptions[index] = {
            id: element.id,
            label: categoryLabel
          };
        });

        //COLLECTİONS
        // this.collections = response.data.collection;
        // this.CollectionOptions = [];
        // this.collections.forEach((element, index) => {
        //   this.CollectionOptions[index] = {
        //     id: element.id,
        //     label: element.title
        //   };
        // });

        if (this.initPage) {
          this.initFilterValues();
          this.initPage = false;
        }

        this.getProducts(this.requestParams);
      } else if (status == 0) {
        this.$vs.loading.close();
        this.$vs.notify({
          color: "danger",
          title: this.$t("Internet_Connection_Message"),
          text: this.$t("Dental_Message_11")
        });
      }
    },

    getFilterValues(params) {
      this.requestParams = params;
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(
        API.BASEURL + API.PRODUCT_FILTER,
        this.handleGetFilterValuesRequest,
        params
      );
    },
    handleGetProductsRequest(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.firstLoading = false;
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (this.newFilter) {
          this.models = [];
          this.newFilter = false;
        }
        var new_models;
        if (data.next == null) {
          this.nextPage = null;
        } else {
          this.nextPage = data.next;
        }

        this.totalProduct = data.count;
        new_models = data.results;
        new_models.forEach(element => {
          element.size = element.size.split(",");
          if (element.size[0] == "General") {
            element.size = "General";
          }
        });

        this.models = [...this.models, ...new_models];
      }
    },

    getProducts(params = {}) {
      var url = "";
      this.hasFilter = false;
      if (this.nextPage == "first" || this.newFilter) {
        url = API.BASEURL + API.PRODUCTS;
      } else if (this.nextPage != null) {
        url = this.nextPage;
      }

      if (Object.keys(params).length === 2) {
        if (params.enamel == false && params.stone == false) {
          this.hasFilter = false;
        } else {
          this.hasFilter = true;
        }
      } else if (Object.keys(params).length == 0 && this.nextPage != null) {
        this.hasFilter = false;
      } else {
        this.hasFilter = true;
      }

      this.nextPage = null;
      if (this.firstLoading) {
        this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      }

      Api.get(url, this.handleGetProductsRequest, params);
    },
    initFilterValues() {
      let queryData = this.$route.query;

      //Enamal and Stone
      this.hasEnamel = queryData.enamel === "true";
      this.hasStones = queryData.stone === "true";

      // Search
      if (queryData.search != null && queryData.search != "") {
        this.searchValue = queryData.search;
        this.searchValueChip = this.searchValue;
        this.isSearchValueActive = true;
      }

      //Category
      if (queryData.category != null) {
        this.categories.forEach(element => {
          if (element.id == queryData.category) {
            this.selectedCategory = {
              id: element.id,
              label: element.title
            };

            this.filterCategoryChips.push(this.selectedCategory.label);
          }
        });
      }

      //Resin Gram
      if (queryData.gold_type != null) {
        if (queryData.gold_type == "14K") {
          this.goldWeight = "14K";

          this.goldWeightRange[0] = (
            queryData.weight_min * this.goldWeightMultipier.gold14K
          ).toFixed(2);
          this.goldWeightRange[1] = (
            queryData.weight_max * this.goldWeightMultipier.gold14K
          ).toFixed(2);
        } else if (queryData.gold_type == "18K") {
          this.goldWeight = "18K";
          this.goldWeightRange[0] = (
            queryData.weight_min * this.goldWeightMultipier.gold18K
          ).toFixed(2);
          this.goldWeightRange[1] = (
            queryData.weight_max * this.goldWeightMultipier.gold18K
          ).toFixed(2);
        } else {
          this.goldWeight = "21K";
          this.goldWeightRange[0] = (
            queryData.weight_min * this.goldWeightMultipier.gold21K
          ).toFixed(2);
          this.goldWeightRange[1] = (
            queryData.weight_max * this.goldWeightMultipier.gold21K
          ).toFixed(2);
        }
        this.filterGoldWeightChips.push(
          this.goldWeightRange[0] + " - " + this.goldWeightRange[1]
        );
      }

      // FS Ratio
      if (queryData.fsr_min != null) {
        this.fsRatio = [
          Math.abs(100 - queryData.fsr_max),
          Math.abs(100 - queryData.fsr_min)
        ];
      }

      //Style
      if (queryData.style != null) {
        var styles = queryData.style;
        if (queryData.style.includes(",")) {
          styles = styles.split(",");

          styles.forEach(elementStyle => {
            this.styleOptions.forEach(elementOption => {
              if (elementStyle == elementOption.id) {
                this.selectedStyle.push(elementStyle);
                this.filterStyleChips.push(elementOption.label);
              }
            });
          });
        } else {
          this.selectedStyle.push(styles);
          this.styleOptions.forEach(elementOption => {
            if (styles == elementOption.id) {
              this.filterStyleChips.push(elementOption.label);
            }
          });
        }
      }

      //SPD
      if (queryData.spd != null) {
        var spdQuery = queryData.spd;
        if (queryData.spd.toString().includes(",")) {
          spdQuery = spdQuery.split(",");
          spdQuery.forEach(elementSpd => {
            this.shapePatternDecoration.forEach(elementOption => {
              if (elementSpd == elementOption.id) {
                this.selectedShapePatternDecoration.push(parseInt(elementSpd));
                this.shapePatternDecoration.forEach(element => {
                  if (element.id == elementSpd)
                    this.filterSpdChips.push(element.title);
                });
              }
            });
          });
        } else {
          this.selectedShapePatternDecoration.push(parseInt(spdQuery));
          this.shapePatternDecoration.forEach(element => {
            if (element.id == spdQuery) this.filterSpdChips.push(element.title);
          });
        }
      }
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    goldWeightMultipier() {
      return this.$store.getters["designMarket/getGoldWeightMultipier"];
    }
  },
  created() {
    this.setSidebarWidth();
    this.getProductWithFilter();
    if (localStorage.getItem("design_market_vip") == "true") {
      this.designMarketVip = true;
    } else {
      this.designMarketVip = false;
    }

    this.$store.dispatch("toggleVerticalMenuHide", true);
  },
  destroyed() {
    this.$store.dispatch("toggleVerticalMenuHide", false);
  }
};
