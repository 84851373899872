<template>
  <div>
    <div class="flex flex-wrap pl-0">
      <vs-popup
        class="p-0"
        fullscreen
        :title="item.title"
        :active.sync="popupProduct"
      >
        <vs-row vs-type="flex" vs-justify="center" vs-align="flex-start">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="flex-start"
            vs-w="8"
            vs-sm="12"
          >
            <div style="position: relative">
              <img
                v-if="item.type == 'V'"
                style="width: 32px; height: 32px"
                class="vipFreeImage"
                src="@/assets/images/pages/vip64.png"
                alt=""
              />
              <img
                v-if="item.type == 'F'"
                style="width: 32px; height: 32px"
                class="vipFreeImage"
                src="@/assets/images/pages/free64.png"
                alt=""
              />

              <img class="popupImage" @error="imageError" :src="getImage" />
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="4"
            vs-sm="12"
          >
            <popupProductDetail
              :title="item.title"
              :resinGr="item.resin_gr"
              :stoneCount="item.stone_count"
            />
          </vs-col>
        </vs-row>
      </vs-popup>
      <div style="position: relative; width: 100%">
        <img
          v-if="item.type == 'V'"
          style="width: 16px; height: 16px"
          class="vipFreeImage"
          src="@/assets/images/pages/vip24.png"
          alt=""
        />
        <img
          v-if="item.type == 'F'"
          style="width: 24px; height: 24px"
          class="vipFreeImage"
          src="@/assets/images/pages/free24.png"
          alt=""
        />

        <img
          v-if="!imageErrorChangePhoto"
          class="itemPhoto mx-auto block"
          @click="
            popupProduct = true;
            changeImage();
          "
          @error="imageError"
          :src="item.thumbnail"
          alt
        />
        <img
          v-else
          class="itemPhoto mx-auto block"
          src="@/assets/images/pages/no_image.png"
          alt
        />
      </div>
      <div class="background flex flex-wrap">
        <vs-row>
          <vs-col class="flex justify-between mt-4 ml-4 mr-4">
            <p class="productIdPrice">{{ item.title }}</p>
          </vs-col>
          <p class="productResin ml-4 mr-4">
            {{
              parseFloat(
                (item.resin_gr * goldWeightMultipier.gold14K).toFixed(1)
              )
            }}
            {{ $t("Gr") }} (14K),
            {{
              parseFloat(
                (item.resin_gr * goldWeightMultipier.gold21K).toFixed(1)
              )
            }}
            {{ $t("Gr") }} (21K)
          </p>
          <vs-col class="flex justify-between m-2 mt-2">
            <input
              placeholder="Quantity"
              class="inputQuantityNumber p-0 m-0"
              v-model="quantity"
              type="number"
            />

            <vs-button
              class="addToCart"
              color="dark"
              type="filled"
              icon="add"
              @click="addItemShop()"
            ></vs-button>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </div>
</template>
<script>
import defaultImage from "@/assets/images/pages/empty.png";
import defaultErrorImage from "@/assets/images/pages/no_image.png";
import popupProductDetail from "./PopupProductDetail";

export default {
  data() {
    return {
      getImage: defaultImage,
      imageErrorChangePhoto: false,
      popupProduct: false,
      quantity: 5,
    };
  },
  props: ["item"],

  components: {
    popupProductDetail,
  },

  computed: {
    goldWeightMultipier() {
      return this.$store.getters["designMarket/getGoldWeightMultipier"];
    },
  },

  methods: {
    changeImage() {
      setTimeout(() => {
        this.getImage = this.item.image;
      }, 10);
    },
    imageError(event) {
      event.target.src = defaultErrorImage;

      return true;
    },
    addItemShop() {
      if (this.quantity < 5) {
        this.quantity = 5;
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Design_Message_2"),
          color: "warning",
        });
        return;
      }

      var differentElement = true;

      var shopItems = this.$store.getters["designMarket/getShopItems"];

      shopItems.forEach((element) => {
        if (element.id === this.item.id) {
          this.$store.dispatch("designMarket/updateItemQuantitySameItem", {
            quantity: this.quantity,
            id: this.item.id,
            size: this.item.size,
          });
          differentElement = false;
        }
      });

      if (differentElement) {
        var item = {
          id: this.item.id,
          title: this.item.title,
          price: this.item.price,
          size: this.item.size,
          selectedSize: [0, 0, 0, 0, 0],
          quantity: this.quantity,
          image: this.item.image,
          thumbnail: this.item.thumbnail,
          resin_gr: this.item.resin_gr,
          minGeneralHeight: this.item.min_general_height,
          minGeneralWidth: this.item.min_general_width,
          isBigProduct: false,
          type: this.item.type,
        };
        this.$store.dispatch("designMarket/addItemInShop", item);
      }
      this.$vs.notify({
        title: this.item.title,
        text: this.quantity + " " + this.$t("Design_Message_3"),
        color: "success",
      });
    },
  },
};
</script>

<style lang="scss">
.addToCart {
  .vs-icon {
    font-size: 1.5em;
  }
}
@media (min-width: 736px) {
  .con-vs-popup.fullscreen .vs-popup {
    width: auto !important;
    height: auto !important;
    .popupImage {
      width: 100%;
      height: auto;
    }
  }
}
@media (max-width: 736px) {
  .con-vs-popup.fullscreen .vs-popup {
    width: 100% !important;
    height: auto !important;
    .popupImage {
      width: 100%;
      height: auto;
    }
  }
}
</style>

<style lang="scss" scoped>
.vipFreeImage {
  position: absolute;
  z-index: 99;
  right: 5%;
  top: 5%;
}
.vs-button {
  padding: 0px;
}

.addToCart {
  background-color: #000000;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  max-height: 1.9em;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
.inputQuantityNumber {
  width: 5em;
  max-height: 2.2em;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 5px;
}
.productResin {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.productIdPrice {
  font-size: 14px;
  font-weight: 800;
  color: #000000;
}
.background {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.itemPhoto {
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
